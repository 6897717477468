import Service from './Service';
import { isEmpty } from 'lodash';
const resource = '/api/usuario';

export default {
    getUserByDocument(documento) {
        let url = `${resource}/get?by=document&documento=${documento}`;
        return Service.get(url);
    },
    index(search = '', pagination = {}) {

        let url = `/api/usuarios-evt/listar?`;

        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    show(id) {
        let url = `/api/usuarios-evt/ver/${id}`;
        return Service.get(url);
    },
    duplicado(value){
        let url = `/api/usuarios-evt/usuario-duplicado/${value}`;
        return Service.get(url);
    },
    genero(){
        let url = `/api/genero/listar`;
        return Service.get(url);
    },
    tipoDocumento(){
        let url = `/api/tipoDocumento/listar`;
        return Service.get(url);
    },
    update(obj){
        let url = `/api/usuarios-evt/vincular`;
        return Service.put(url, obj);
    },
    store(data){
        return Service.post(`/api/usuarios-evt/store`, data);
    },
}