<template>
  <data-grid :data="pagination" @search="index">
      <template #action-button>
          <create v-on:saved="index"></create>
      </template>
      <template #grid>
          <grid-table>
              <template #head>
                  <grid-row>
                      <grid-cell-header v-for="(h,i) in headers" :key="i">{{h}}</grid-cell-header>
                  </grid-row>
              </template>
              <template #body>
                  <grid-row v-for="(acudiente,index) in pagination.data" :key="`acudiente_${index}`">
                      <grid-cell style="width: 10px">{{acudiente.tipo_documento.codigo}}</grid-cell>
                      <grid-cell class="text-center">{{acudiente.numero_documento}}</grid-cell>
                      <grid-cell>{{acudiente.nombres}}</grid-cell>
                      <grid-cell>{{acudiente.apellidos}}</grid-cell>
                      <grid-cell class="text-center">
                          <button class="btn btn-sm btn-icon btn-success" title="Seleccionar" @click="selected(acudiente.id)">
                              <i class="fa fa-link fa-fw"></i>
                          </button>
                      </grid-cell>
                  </grid-row>
              </template>
          </grid-table>
      </template>
  </data-grid>
</template>

<script>
import DataGrid from "@/components/common/utilities/DataGrid/DataGrid.vue";
import GridTable from "@/components/common/utilities/DataGrid/GridTable.vue";
import GridCellHeader from "@/components/common/utilities/DataGrid/GridCellHeader.vue";
import GridRow from "@/components/common/utilities/DataGrid/GridRow.vue";
import GridCell from "@/components/common/utilities/DataGrid/GridCell.vue";
import paginateMixin from "@/mixins/PaginateMixin";
import acudienteService from "@/services/acudienteService";
import Create from "./create.vue";

const list = async (search, pagination) => acudienteService.index({
    page : pagination.page,
    per_page : pagination.per_page,
    search : search
});
export default {
    name: "index",
    mixins: [paginateMixin(list)],
    components: {Create, GridCell, GridRow, GridCellHeader, GridTable, DataGrid},
    created() {
        this.index();
    },
    data(){
        return {
            headers : [
                'Tipo Documento',
                'Nro Documento',
                'Nombre',
                'Apellidos',
                ''
            ],
            pagination : {
                data : [],
                per_page : 10,
                page : 1,
                current_page : 1,
            }
        }
    },
    methods :{
        selected(id){
            this.$emit('selected', id);
        }
    }
}
</script>

<style scoped>

</style>
