<template>
    <main>
        <page-header-compact>REGISTRO DE PADRE</page-header-compact>
         <div class="container-xl px-4 mt-n15" >
             <div class="card shadow-sm">
                 <div class="card-body">
                     <index @selected="vincularUsuario"></index>
                 </div>
             </div>
         </div>
    </main>
</template>

<script>
import PageHeaderCompact from "../../components/layouts/content/page-header-compact";
import Swal from "sweetalert2";
import index from "../PAI/acudiente/index.vue"
import usuariosEvtService from "../../services/usuariosEvtService";
export default {
    name: "create.vue",
    components: {
        PageHeaderCompact,
        index
    },
    data () {
        return {
            idUsuario:'',
            usuario : {},
            eps:{},
            tipoDocumentos: [],
        }
    },
    methods: {
        async vincularUsuario(id){
            try {
                this.LoaderSpinnerShow();

                await usuariosEvtService.update({
                    id_usuario: this.idUsuario,
                    id : id,
                });
                this.LoaderSpinnerHide();
                await Swal.fire({
                    title: "Exito!",
                    text: "Acudiente vinculado con exíto",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                this.$router.push({
                    name: 'pail.perfil.usuario', params: { id:this.id_usuario }
                });
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Swal.fire('Ups!', 'ha ocurrido un error al procesar la solicitud', 'error');
            }
        },
    },
    async created() {
        this.idUsuario=this.$route.params.idUsuario;
    }
}
</script>

<style scoped>

</style>
