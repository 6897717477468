<template>
  <main>
      <button class="btn btn-info btn-sm mb-2" data-toggle="modal" data-target="#modal">
          <i class="fas fa-plus"></i> Nuevo
      </button>
      <div id="modal" class="modal fade" data-backdrop="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header">
                      <div class="modal-title text-md">
                          <!-- icon plus -->
                          <i class="fas fa-plus"></i>
                          Nuevo Accudiente
                      </div>
                      <button class="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div class="modal-body">
                      <div class="row">
                          <div class="col-6">
                              <label class="small mb-0">Tipo Documento</label>
                              <select
                                      class="form-control form-control-sm"
                                      :class="{'is-invalid': $v.acudiente.id_tipo_documento.$error}"
                                      v-model="acudiente.id_tipo_documento">
                                  <option v-for="(tipo,i) in tipoDocumentos" :value="tipo.id" :key="`tipo${i}`">
                                      {{tipo.codigo}} {{tipo.nombre}}
                                  </option>
                              </select>
                          </div>
                          <div class="col-6">
                              <label class="small mb-0">Numero Documento</label>
                              <input class="form-control form-control-sm"
                                      :class="{'is-invalid': $v.acudiente.numero_documento.$error}"
                                      type="number" v-model="acudiente.numero_documento">
                          </div>
                      </div>
                      <div class="row mt-2">
                          <div class="col-6">
                              <label class="small mb-0">Nombres</label>
                              <input class="form-control form-control-sm"
                                      :class="{'is-invalid': $v.acudiente.nombres.$error}"
                                      v-model="acudiente.nombres">
                          </div>
                          <div class="col-6">
                              <label class="small mb-0">Apellidos</label>
                              <input class="form-control form-control-sm"
                                      :class="{'is-invalid': $v.acudiente.apellidos.$error}"
                                      v-model="acudiente.apellidos">
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-light btn-sm shadow-sm" data-dismiss="modal" @click="clearForm()">
                          <i class="fas fa-times fa-fw"></i>
                          Cerrar
                      </button>
                      <button type="button" class="btn btn-success btn-sm shadow-sm" @click="save()">
                          <i class="fas fa-save fa-fw"></i>
                          Guardar
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </main>
</template>

<script>
import usuariosEvtService from "@/services/usuariosEvtService";
import acudienteService from "@/services/acudienteService";
import swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
export default {
    name: "create",
    data(){
      return {
          tipoDocumentos : [],
          acudiente : {
              id_tipo_documento : '',
              numero_documento : '',
              nombres : '',
              apellidos : ''
          }
      }
    },
    validations(){
        return {
            acudiente : {
                id_tipo_documento : {
                    required
                },
                numero_documento : {
                    required
                },
                nombres : {
                    required
                },
                apellidos : {
                    required
                }
            }
        }
    },
    async created() {
        const response= await usuariosEvtService.tipoDocumento();
        this.tipoDocumentos = response.data;
    },
    methods: {
        async save(){
            this.$v.$touch();
            if(this.$v.$invalid){
                swal.fire({
                    title: "Error",
                    text: "Por favor complete los campos requeridos",
                    icon: "warning",
                    confirmButtonText: "Aceptar",
                });
                return;
            }
            try{
                this.LoaderSpinnerShow()
                const {data} = await acudienteService.store(this.acudiente);
                this.$emit('saved', data);
                this.LoaderSpinnerHide();
                swal.fire({
                    title: "Guardado",
                    text: "Acudiente guardado correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });
                this.clearForm();
            }catch (e) {
                this.LoaderSpinnerHide();
                console.log(e);
                swal.fire({
                    title: "Error",
                    text: "Error al guardar el acudiente",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }

        },
        clearForm(){
            this.acudiente = {
                id_tipo_documento : '',
                numero_documento : '',
                nombres : '',
                apellidos : ''
            }
        },
    }
}
</script>

<style scoped>

</style>
